// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword  } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDFhg0o79HkwtUgGoF8u0TWFmuHstJlPnk",
    authDomain: "prod-backends.firebaseapp.com",
    databaseURL: "https://prod-backends-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "prod-backends",
    storageBucket: "prod-backends.appspot.com",
    messagingSenderId: "1080775033707",
    appId: "1:1080775033707:web:8bb9a1c77b347154cb422e",
    measurementId: "G-P1ND9CTSBF"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth();
const firestore = getFirestore(firebaseApp);

export {
  auth,
  firebaseApp,
  firestore,
  signInWithEmailAndPassword
}
