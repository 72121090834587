export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Portal"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo i18n !!"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anmeldeinformationen. Bitte versuchen Sie es erneut."])}
    }
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patienten"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktiv"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Sitzungen"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespielt"])},
    "games_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele/Tag"])},
    "session_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen/Tag"])},
    "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übungen"])}
  }
}