export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail d'administration"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour i18n !!"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiants incorrects. Veuillez réessayer."])}
    }
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière activité"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de sessions"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joué"])},
    "games_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux par jour"])},
    "session_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions par jour"])},
    "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercices"])}
  }
}