import {RouteRecordRaw} from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Auth/LoginView.vue'),
    meta: {
      title: "Home",
      requiresAuth: false
    }
  },
  {
    path: '/admin/dashboard',
    name: 'AdminIndex',
    component: () => import('../views/Admin/DashboardIndex.vue'),
    meta: {
      title: "Admin Dashboard",
      requiresAuth: true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: "404",
    component: () => import('../views/site/404View.vue'),
    meta: {
      title: "404 Not Found"
    }
  },
];

export default routes;
