export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Portal"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad credentials Please try again."])}
    }
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Active"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Sessions"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played"])},
    "games_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games/Day"])},
    "session_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session/Day"])},
    "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercises"])}
  }
}